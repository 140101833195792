import { createPage } from '@app/nextjs'

import { HomePage } from '@app/features/tenants'
import { CurrentUserProvider } from '@app/features/core/hooks/use-current-user'

export default createPage({
  layout: 'fullscreen',
  renderComponent: () => {
    return (
      <CurrentUserProvider>
        <HomePage />
      </CurrentUserProvider>
    )
  },
})
